import { BackIcon, EmailIcon1, WalletIcon1 } from "assets";
import { Images } from "assets/Images";
import { Button } from "components/Atoms/Button/Button";
import { Textfield } from "components/Atoms/Textfield/Textfield";
import { Typography } from "components/Atoms/Typography/Typography";
import { useFormik } from "formik";
import useGetRecoveryAccount from "hooks/auth/useRecoveryAccount";
import useSendRecovery from "hooks/auth/useSendRecovery";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import TimeoutButton from "./TimeoutButton";

const AccountRecovery = () => {
  const navigate = useNavigate();
  const { handleGetRevoveryData, status } = useGetRecoveryAccount();
  const { handleSendRcoveryAsync, status: recoverStatus } = useSendRecovery();
  const [isSendLink, setIsSendLink] = useState<boolean>(false);
  const [isVerified, setIsVerified] = useState(false);
  const [data, setData] = useState<{
    avatar: string;
    email: string;
    id: string;
    success: boolean;
    username: string;
    wallet_address: string;
  }>();
  const validationSchema = Yup.object({
    email: Yup.string()
      .matches(/^\S*$/, "No spaces allowed")
      .required("Required"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const res = await handleGetRevoveryData({ input: values.email });
      if (res.success) {
        setIsVerified(true);
        setData(res);        
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div onClick={()=>navigate('/sign-in')} className="text-text-primary cursor-pointer absolute top-5 left-5 z-50 mobile:top-3 mobile:left-3">
        <BackIcon /> 
      </div>
      <div className="flex justify-center items-center text-center">
        <Typography
          variant="h2"
          isIcon={false}
          className="text-text-primary mobile:text-xl mobile:leading-[32px] uppercase"
        >
          Account Recovery
        </Typography>
      </div>
      <div className="py-3 mobile:py-3">
        <img src={Images.LINE} alt="line" />
      </div>

      {isVerified ? (
        <Typography
          isIcon={false}
          className="text-text-light text-center uppercase my-3"
        >
          You will receive a link to verify your email at the registered
          address. You can use that link to Recover your account.
        </Typography>
      ) : (
        <Typography
          isIcon={false}
          className="text-text-light text-center uppercase my-3"
        >
          Please enter your registered email, User name or wallet address.
        </Typography>
      )}

      <div className="flex flex-col gap-3 mobile:gap-2 mx-auto w-[90%]">
        {isVerified ? (
          <>
            <div className="bg-text-black75 text-text-primary rounded-lg p-2">
              <div className="flex items-center gap-2">
                <div className="w-5 h-5 rounded-full">
                  <img
                    src={data?.avatar}
                    alt="profile"
                    className="w-full h-full object-cover"
                  />
                </div>
                <Typography isIcon={false}>{data?.username || "-"}</Typography>
              </div>
              <div className="flex items-center gap-2">
                <div className="w-5 h-5 pt-1">
                <EmailIcon1 />
                </div>
                <Typography isIcon={false}>{data?.email || "-"}</Typography>
              </div>
              <div className="flex items-start gap-2">
                <div className="w-5 h-5 pt-1">
                <WalletIcon1 />
                </div>
                <Typography isIcon={false} className="break-all">
                  {data?.wallet_address || "-"}
                </Typography>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="w-full relative">
              <Textfield
                placeHolder="Email, User Name or Wallet Address"
                name="email"
                value={formik.values.email}
                // onChange={formik.handleChange}
                onChange={(e: any) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                className={`${
                  formik.touched.email && formik.errors.email
                    ? "!border-red-600"
                    : "border-none"
                }`}
              />
            </div>
            {formik.touched.email && formik.errors.email && (
              <Typography
                isIcon={false}
                variant="p"
                font="regular"
                className="!text-red-600"
              >
                {formik.errors.email}
              </Typography>
            )}
          </>
        )}
        <div className="group flex flex-col justify-center items-center mt-5 gap-2">
          {isVerified && (
            <Button
              bgColor={true}
              CLASSNAME="px-2 text-text-primary group-hover:text-text-secondary"
              type="button"
              size="extraSmall"
              onClick={async() => {
                await handleSendRcoveryAsync({ input: data?.id || "" });
                setIsSendLink(true);
              }}
              color="white"
              disable={recoverStatus.isLoading || isSendLink}
              isBorderLabel={"SEND LINK"}
              isBorder={true}
            />
          )}
          {!isVerified && (
            <Button
              bgColor={true}
              CLASSNAME="px-2 text-text-primary group-hover:text-text-secondary"
              type="submit"
              size="extraSmall"
              color="white"
              disable={status.isLoading}
              isBorderLabel={"VERIFY"}
              isBorder={true}
            />
          )}
          {isVerified && (
            <Typography
              isIcon={false}
              className="text-text-primary text-center"
            >
              Didn't receive a link?{" "}
              <TimeoutButton
                buttonName="Resend"
                onclick={() =>
                  handleSendRcoveryAsync({ input: data?.id || "" })
                }
                isSendLink={isSendLink}
                time={20}
              />
            </Typography>
          )}
        </div>
      </div>
    </form>
  );
};

export default AccountRecovery;
