import { useCustomError } from "hooks/accessTokenExpire";
import { useState } from "react";
import { GET_RECOVERY_ACCOUNT_AUTH } from "services/apiService";

interface StatusInterface {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
}

const useGetRecoveryAccount  = () => {
  const [status, setStatus] = useState<StatusInterface>({
    isLoading: false,
    isError: false,
    isSuccess: false,
  });
  const { handleError } = useCustomError();
  const handleGetRevoveryData = async (param: {
    input: string;
  }) => {
    try {
      setStatus({
        ...status,
        isError: false,
        isSuccess: false,
        isLoading: true,
      });
      const req = await GET_RECOVERY_ACCOUNT_AUTH(param.input);
      const res = req.data;
      return res;
    } catch (error: any) {
      setStatus(prev => {return{
        ...prev,
        isError: true,
      }});
      handleError(error);
    } finally {
      setStatus(prev => {return{
        ...prev,
        isLoading: false,
      }});
    }
  };
  
  return {
    handleGetRevoveryData,
    status,
  };
};

export default useGetRecoveryAccount;