import { SpeedTimer } from 'assets';
import { Typography } from 'components/Atoms/Typography/Typography';
import { useDailyBonus } from 'hooks/useDailyBonus';
import { Timer } from '../Referrals_3X/components/TimerReferrals';
import { useUser } from 'context/userContext';

export const DailySpin = () => {
  const {configData} = useUser()
  const {data,isStartTime,setIsStartTime} = useDailyBonus(true,configData?.daily_spin_bonus_start_time,configData?.daily_spin_bonus_end_time)

  return (
    <div className='w-full'>
        {isStartTime && <div className='flex flex-row items-center justify-between gap-2 md:mt-2 pl-1'>
          <div className='flex flex-row items-center gap-4 sm:gap-[1.80rem] pl-3 sm:pl-6'>
            <div className='flex-shrink-0'>
              <SpeedTimer />
            </div>
          
          <div className='flex flex-col text-white sm:gap-1'>
            <Typography isIcon={false} className='uppercase !text-[10px] !cursor-default'>
              limited time boost active
            </Typography>
            <Typography isIcon={false} className='capitalize text-[#E8A106] !text-xxs mobile:!text-[8px] !cursor-default'>
            Spin the wheel & win 10 shards extra.
            </Typography>
          </div>
       
          </div>
          <div className='flex flex-row item-center gap-3 justify-between'>
          <Timer data={data} isStartTime={isStartTime} setIsStartTime={setIsStartTime} />
          <Typography isIcon={false} className='text-text-secondary uppercase text-lg italic !cursor-default'>Left</Typography>
          </div>
        </div>}
       
    </div>
  )
}
