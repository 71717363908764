import { Typography } from "components/Atoms/Typography/Typography";
import React, { useEffect, useState } from "react";

const TimeoutButton = ({
  buttonName,
  onclick,
  time,
  isSendLink = false,
}: {
  buttonName: string;
  onclick: () => void;
  time: number;
  isSendLink?: boolean;
}) => {
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [timeLeft, setTimeLeft] = useState(time ? time : 20);

  useEffect(()=>{
    if(isSendLink){
      setIsButtonVisible(false);
    }
  },[isSendLink])

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (!isButtonVisible && timeLeft > 0) {
      timer = setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsButtonVisible(true);
      setTimeLeft(time); // Reset the timer
    }    
    return () => clearTimeout(timer);
  }, [isButtonVisible, timeLeft]);
  

  const handleButtonClick = () => {
    onclick();
    setIsButtonVisible(false);
  };

  return (
    <>
      {isButtonVisible ? (
        <span
          onClick={() => isSendLink && handleButtonClick()}
          // isIcon={false}
          className={`text-text-secondary cursor-pointer ${isSendLink ? '!cursor-pointer' : '!cursor-not-allowed'}`}
        >
          {buttonName}
        </span>
      ) : (
        <span>Retry in {timeLeft} Sec</span>
      )}
    </>
  );
};

export default TimeoutButton;
