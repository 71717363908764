import { CancleIcon } from "assets";
import { Images } from "assets/Images";
import { Card } from "components/Atoms/Card/Card";
import { Typography } from "components/Atoms/Typography/Typography";
import { Modal } from "components/Molecules/Modal";
import React from "react";
import { ShareCode } from "./ShareCode";
import { copyHandler } from "constants/utils";

interface ShareCodeModalProps {
  closeShareCode: () => void;
  storeCodes: string | undefined;
}

export const ShareCodeModal = ({
  closeShareCode,
  storeCodes,
}: ShareCodeModalProps) => {
  return (
    <Modal blurImg>
      <Card pseudoElement="default" className="!px-0 !py-2 md:!h-64">
        <button
          onClick={() => closeShareCode()}
          className="cursor-pointer absolute top-2 right-2 z-30"
        >
          <CancleIcon />
        </button>
        <div className="flex flex-col justify-center items-center relative overflow-hidden">
          <Typography
            isIcon={false}
            variant="h2"
            font="bold"
            className="text-text-primary uppercase cursor-default mobile:text-xxxl text-center px-2"
            >
            Invite and get a 3% bonus $RVV on their purchase.
          </Typography>
          <div className="flex justify-center items-start gap-1 md:px-2 ">
            <p className="text-text-primary text-small break-all max-w-64 md:max-w-md md:text-lg">
              {/* {!refferalLink 
              ? "Referral URL will be generated after completing mandatory X task" 
              : refferalLink} */}
              {storeCodes}
            </p>
            {storeCodes && (
              <div
                className="w-5 h-5 cursor-pointer "
                onClick={() => {
                  copyHandler({
                    data: storeCodes,
                    label: "REFERRAL LINK",
                  });
                }}
              >
                <img
                  src={Images.COPY}
                  alt="copyicon"
                  className="h-full w-full object-contain"
                />
              </div>
            )}
          </div>
          <div className="my-2 md:my-3 ">
            <img src={Images.LINE} alt="" />
          </div>

          <ShareCode BtnText="back to supernova" dataUrl={storeCodes} onClose={closeShareCode}/>
        </div>
      </Card>
    </Modal>
  );
};
