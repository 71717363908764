
function Maintenance() {
  return (
    <div className="maintenance-mode">
        <h1 className="maintenance-mode">Black Pass is scheduled for maintenance. Come back after a few hours!</h1>
    </div>
  );
}

export default Maintenance;
