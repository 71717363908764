import { useCustomError } from "hooks/accessTokenExpire";
import { useState } from "react";
import { toast } from "react-toastify";
import { POST_SIGNUP } from "services/apiService";

interface StatusInterface {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
}

const useSignupAuth = () => {
  const [status, setStatus] = useState<StatusInterface>({
    isLoading: false,
    isError: false,
    isSuccess: false,
  });
  const { handleError } = useCustomError();
  const handleSignupAsync = async (body: {
    username: string;
    email: string;
    referralCode?: string;
  }) => {
    try {
      setStatus({
        ...status,
        isError: false,
        isSuccess: false,
        isLoading: true,
      });
      const req = await POST_SIGNUP(body);
      const res = req.data;
      if (res.success) {
        setStatus(prev => {return {
          ...prev,
          isSuccess: true,
        }});
        toast.success(res.message);
        sessionStorage.removeItem("refferalCode");
      }
    } catch (error: any) {
      setStatus(prev => {return {
        ...prev,
        isError: true,
      }});
      handleError(error);
    } finally {
      setStatus(prev => {return {
        ...prev,
        isLoading: false,
      }});
    }
  };
  return {
    handleSignupAsync,
    status,
  };
};

export default useSignupAuth;
