// export const ENV = process.env.REACT_APP_ENV || "PROD";
export const ENV =
  window.location.href.includes("astranova.world") ||
    window.location.href.includes("black-pass-frontend-prod.azurewebsites.net")
    ? "PROD"
    : "DEV";
export const REACT_APP_FRONTEND_URL =
  process.env[`REACT_APP_FRONTEND_URL_${ENV}`];
export const REACT_APP_RECAPTCHA_KEY =
  process.env[`REACT_APP_RECAPTCHA_KEY_${ENV}`];
export const TELEGRAM_BOT = process.env[`REACT_APP_TELEGRAM_BOT_${ENV}`];
export const CONTRACT_ADDRESS =
  process.env[`REACT_APP_CONTRACT_ADDRESS_${ENV}`];
export const SITE_URL = process.env[`REACT_APP_SITE_URL_${ENV}`];
export const EXPLORER_LINK = process.env[`REACT_APP_EXPLORER_LINK_${ENV}`];
export const RPC_LINK = process.env[`REACT_APP_RPC_LINK_${ENV}`];
export const MARKETPLACE_COLLECTION_URL = `${process.env[`REACT_APP_MARKETPLACE_COLLECTION_URL_${ENV}`]}${CONTRACT_ADDRESS}`;
export const RAINBOW_PROJECT_ID = process.env[`REACT_APP_RAINBOW_PID_${ENV}`];
export const MAINTENANCE_MODE = process.env[`REACT_APP_MAINTENANCE_MODE_${ENV}`];

export const DEFAULT_QUEST_LIMIT = 20;
export const FEEDBACK_EMAIL = "mailto:contact@astranova.world";
export const ASTRANOVA_TWITTER_LINK = "https://twitter.com/Astra__Nova";
export const ASTRANOVA_SPHERE_LINK = `https://sphere.market/immutable/collection/${CONTRACT_ADDRESS}`;
export const ASTRANOVA_TOKEN_TROVE_LINK = "https://tokentrove.com/collection/AstraNovaBlackPass";
export const APP_NAME = "Astra Nova Black Pass";
export const APP_DESC = "The Black Pass is a SocialFI platform accessible through a free soulbound NFT, mintable at the time of joining. On this platform, you'll undertake social quests and farm shards (points) to earn the $RVV token airdrop, the official token of the Astra Nova ecosystem. It all begins with Season 1, centered around Astra Nova's upcoming game demo.";
export const APP_ICON = "https://blackpassmetadatapro.blob.core.windows.net/public/avatar/astroLogo-removebg-preview.png";
export const ASTRA_DEVIANTS_URL = "https://opensea.io/collection/deviants-of-astranova";

//Immutable
export const IMTBL_PUBLISHABLE_KEY = process.env[`REACT_APP_IMTBL_PUBLISHABLE_KEY_${ENV}`];
export const IMTBL_CLIENT_ID = process.env[`REACT_APP_IMTBL_CLIENT_ID_${ENV}`];
export const IMTBL_REDIRECT_URL = ENV === "PROD" ? "https://blackpass.astranova.world/redirect" : "https://black-pass-staging.azurewebsites.net/redirect";
export const IMTBL_LOGOUT_URL = ENV === "PROD" ? "https://blackpass.astranova.world" : "https://black-pass-staging.azurewebsites.net";
export const SIGNING_MESSAGE = process.env[`REACT_APP_SIGNING_MSG_${ENV}`];

//Dasboard 
export const BANNER_REDIRECT_LINK = "https://presale.astranova.world"

//super-nova
export const POLYGON_CHAINID = ENV === "PROD" ? 137 : 2442;

export const SUPER_NOVA_NFT_CONTRACT_ADDRESS = ENV === "PROD" ? "0xe3a6672029733811440091de2e79ac3fbbac3342" : "0xD7C08697C61d96890D8b9d44d418F258C5B2AA58";
export const SUPER_NOVA_STAKE_CONTRACT_ADDRESS = ENV === "PROD" ? "0x804be6E2e0256a03c0388fe26c1CbEAf569969e3" : "0x401886461Db05F3a565A3c2dA51A8886177db890";

export const SUPER_NOVA_STAKE_DURATION =  60
export const SUPER_NOVA_STAKE_REWARD_DURATION =  60

export const clearLocalStorageExceptVaiable = 'remember'

export const BOBA_CONTRACT_ADDRESS = ENV === "PROD" ? "0xa470952ABc41ff96d2EC8FdbbA34fAc83813b817" : "0xDa5960c1a673991840b30478e11085D0Ab5cC055"
export const BOBA_CHAIN_ID = ENV === "PROD" ? 56288 : 9728;
export const ZKEVM_CHAIN_ID = ENV === "PROD" ? 13371 : 13473;
export const BOBA_RPC_LINK = ENV === "PROD" ? "https://boba-bnb.gateway.tenderly.co" : "https://boba-bnb-testnet.gateway.tenderly.co";