import { CancleIcon } from "assets";
import { Card } from "components/Atoms/Card/Card";
import { Modal } from "components/Molecules/Modal";

const rpcUrl = [
  "https://polygon-pokt.nod.es",
  "https://polygon.rpc.blxrbdn.com",
  "https://1rpc.io/matic",
  "https://polygon-mainnet.public.blastapi.io",
];

export default function RpcErrorModal({ close }: { close: () => void }) {
  return (
    <Modal blurImg>
      <Card
        pseudoElement="default"
        className="!px-10 !pb-5 mobile:!pb-2 mobile:!px-5 !h-min !overflow-hidden"
        fullWidth={true}
        childClassName="!overflow-hidden !w-full !h-full over"
      >
        <button
          onClick={() => close()}
          className="cursor-pointer absolute top-2 right-2 z-30"
        >
          <CancleIcon />
        </button>
        <div className=" text-text-primary w-full h-full text-2xl text-center mb-2">
          It seems that the current Polygon RPC URL set in MetaMask is not
          working. To change the RPC URL, please follow these steps:
          <div className="text-start w-full py-3 space-y-4">
            <div className="w-full">
              1. <b>Open MetaMask:</b>{" "}
              <span className="text-xl">
                Click on the MetaMask extension in your browser.
              </span>
            </div>
            <div className="w-full">
              2. <b>Settings:</b>{" "}
              <span className="text-xl">
                Click on the three horizontal lines (menu) in the top-right
                corner and select "Settings".{" "}
              </span>
            </div>
            <div className="w-full">
              3. <b>Networks:</b>{" "}
              <span className="text-xl">
                In the Settings menu, select "Networks".
              </span>{" "}
            </div>
            <div className="w-full">
              4. <b>Edit Network:</b>
              <span className="text-xl">
                {" "}
                Find the Polygon network in the list and click on "Edit".
              </span>
            </div>
            <div className="w-full">
              5. <b>Change RPC URL:</b>
              <span className="text-xl">
                {" "}
                Update the "RPC URL" field with one of the following URLs and
                click "Save":
              </span>
            </div>
          </div>
          <ul className="w-full mb-2">
            {rpcUrl.map((url) => (
              <li key={url} className="text-start underline">
                {url}
              </li>
            ))}
          </ul>
          After updating, you can try again to stake your NFT.
        </div>
      </Card>
    </Modal>
  );
}
