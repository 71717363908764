import { Images } from "assets/Images";
import { Card } from "components/Atoms/Card/Card";
import { HeroSection } from "components/Molecules/HeroSection";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AuthLayout = ({ Component , navigatePath }: { Component: React.FC; navigatePath?:string; }) => {
  const navigate = useNavigate();
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      navigate("/");
    }
  }, []);

  return (
    <HeroSection bgSrc={Images.BG}>
      <Card
        pseudoElement="perfect"
        isCrossIconVisible
        crossOnClick={() => navigate(navigatePath ? navigatePath : "/")}
      >
        {<Component />}
      </Card>
    </HeroSection>
  );
};

export default AuthLayout;
