export const VIDEOS = {
    ASTRO_WIN : require("./res/AstroNova_Web.webm"),
    ASTRO_MAC : require("./res/AstroNova_Mac.mov"),
    BAN_WEB : require("./res/Banner/630x534WEB.mp4"),
    BAN_MOB : require("./res/Banner/332x534MOBILE.mp4"),
    ASTRANOVA : require("./res/Banner/Astronova.mp4"),
    ASTRANOVA_MOB : require("./res/Banner/Astronova_mob.mp4") ,
    UPDATE_WEB_ASTRANOVA : require("./res/Banner/630x534WEB GIF.mp4") ,
    UPDATE_MOB_ASTRANOVA : require("./res/Banner/332x534MOBILE MP4.MP4"),
    TRESURY_BOX : require("./res/chest_5.gif.mp4") ,
}