import React, { useState, ReactNode, useEffect } from "react";
import clsx from "clsx";

interface TooltipProps {
  tooltipText: string;
  children: ReactNode;
  className ?: string;
}

const Tooltip: React.FC<TooltipProps> = ({ tooltipText, children,className }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleClick = () => {
    setShowTooltip(!showTooltip);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const tooltipElement = document.querySelector(".tooltip-container");
      if (tooltipElement && !tooltipElement.contains(event.target as Node)) {
        setShowTooltip(false);
      }
    };

    if (showTooltip) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showTooltip]);

  return (
    <div className="relative inline-block tooltip-container">
      <div onClick={handleClick} className="cursor-pointer">
        {children}
      </div>
      {showTooltip && (
        <div
          className={
            clsx("absolute mobile:bottom-5 mobile:right-0 md:top-0 md:left-5 z-50 min-w-44 w-full transform rounded bg-black/80 px-2 py-1 text-sm text-white cursor-default text-center",
            className)
          }
        >
          {tooltipText}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
