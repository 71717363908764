import { EyeIcon, EyeSlashIcon } from "assets";
import { Images } from "assets/Images";
import { Button } from "components/Atoms/Button/Button";
import { Textfield } from "components/Atoms/Textfield/Textfield";
import { Typography } from "components/Atoms/Typography/Typography";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import Succuss from "./Success";
import useSetPassword from "hooks/auth/useSetPassword";

const SetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const type = searchParams.get("type");
  const { handleSetPasswordForNewUsers, handleSetPassword, status } =
    useSetPassword();
  const [showPassword, setShowPassword] = useState(false);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const validationSchema = Yup.object({
    password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .max(20, "Password must be at most 20 characters long")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/[0-9]/, "Password must contain at least one numeric digit")
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        "Password must contain at least one special character"
      )
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (value, { resetForm }) => {
      if (type === "recovery") {
        await handleSetPassword({
          token: token || "",
          password: value.password,
        });
      } else {
        handleSetPasswordForNewUsers({
          token: token || "",
          password: value.password,
        });
      }
      resetForm();
    },
  });

  useEffect(() => {
    if (!token) {
      navigate("/sign-up");
    }
  }, [token]);

  return (
    <>
      {!status.isSuccess ? (
        <form onSubmit={formik.handleSubmit}>
          <div className="flex justify-center items-center text-center">
            <Typography
              variant="h2"
              isIcon={false}
              className="text-text-primary mobile:text-xl mobile:leading-[32px]"
            >
              SET PASSWORD
            </Typography>
          </div>
          <div className="py-3 mobile:py-3">
            <img src={Images.LINE} alt="line" />
          </div>

          <div className="flex flex-col gap-3 mobile:gap-2">
            <Typography isIcon={true} font="regular" variant="p">
              Password
            </Typography>
            <div className="w-full relative ">
              <Textfield
                placeHolder="Password"
                name="password"
                type={showPassword ? "text" : "password"}
                value={formik.values.password}
                // onChange={formik.handleChange}
                onChange={(e: any) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                className={`${
                  formik.touched.password && formik.errors.password
                    ? "!border-red-600"
                    : "border-none"
                }`}
                rightIcon={showPassword ? <EyeIcon /> : <EyeSlashIcon />}
                onClick={(e: any) => {
                  e.stopPropagation();
                  setShowPassword(!showPassword);
                }}
              />
              {/* <div className="absolute top-0 right-2 bottom-0 flex justify-center items-center text-light-gray-200">
                {userNameValidation &&
                userNameValidation === 200 &&
                !formik.errors.username ? (
                  <RightLogo />
                ) : userNameValidation === 409 &&
                  formik.values.username !== "" ? (
                  <WrongLogo />
                ) : (
                  ""
                )}
              </div> */}
            </div>
            {formik.touched.password && formik.errors.password && (
              <Typography
                isIcon={false}
                variant="p"
                font="regular"
                className="!text-red-600"
              >
                {formik.errors.password}
              </Typography>
            )}
            <Typography isIcon={true} font="regular" variant="p">
              Confirm Password
            </Typography>
            <div className="w-full relative ">
              <Textfield
                placeHolder="Password"
                name="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                value={formik.values.confirmPassword}
                // onChange={formik.handleChange}
                onChange={(e: any) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                className={`${
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                    ? "!border-red-600"
                    : "border-none"
                }`}
                rightIcon={showConfirmPassword ? <EyeIcon /> : <EyeSlashIcon />}
                onClick={(e: any) => {
                  e.stopPropagation();
                  setShowConfirmPassword(!showConfirmPassword);
                }}
              />
              {/* <div className="absolute top-0 right-2 bottom-0 flex justify-center items-center text-light-gray-200">
                {userNameValidation &&
                userNameValidation === 200 &&
                !formik.errors.username ? (
                  <RightLogo />
                ) : userNameValidation === 409 &&
                  formik.values.username !== "" ? (
                  <WrongLogo />
                ) : (
                  ""
                )}
              </div> */}
            </div>
            {formik.touched.confirmPassword &&
              formik.errors.confirmPassword && (
                <Typography
                  isIcon={false}
                  variant="p"
                  font="regular"
                  className="!text-red-600"
                >
                  {formik.errors.confirmPassword}
                </Typography>
              )}

            <div className="group flex flex-col justify-center items-center mt-5 gap-2">
              <Button
                bgColor={true}
                CLASSNAME="px-2 text-text-primary group-hover:text-text-secondary"
                type="submit"
                size="extraSmall"
                color="white"
                isBorderLabel="CONFIRM"
                isBorder={true}
                disable={status.isLoading}
              />
            </div>
          </div>
        </form>
      ) : (
        <Succuss
          success={type === "recovery" ? "forgot" : "created"}
          onclick={() => navigate(type === "recovery" ? "/" : "/sign-in")}
        />
      )}
    </>
  );
};

export default SetPassword;
