import { Images } from "assets/Images";
import { Button } from "components/Atoms/Button/Button";

import { Typography } from "components/Atoms/Typography/Typography";


const Succuss = ({
  success,
  onclick,
}: {
  success: "created" | "forgot";
  onclick: () => void;
}) => {

  return (
    <>
      {/* //created */}
      {success === "created" && (
        <>
          {" "}
          <div className="flex flex-col justify-center items-center text-center">
            <Typography
              variant="h2"
              isIcon={false}
              className="text-text-primary mobile:text-xl mobile:leading-[32px]"
            >
              ACCOUNT CREATED
            </Typography>
            <Typography
              variant="h2"
              isIcon={false}
              className="text-text-primary mobile:text-xl mobile:leading-[32px]"
            >
              SUCCESSFULLY!
            </Typography>
          </div>
          <div className="group flex flex-col justify-center items-center mt-5 gap-2">
            <Button
              bgColor={true}
              CLASSNAME="px-2 text-text-primary group-hover:text-text-secondary"
              type="button"
              size="extraSmall"
              color="white"
              isBorderLabel="GO TO SiGN IN"
              isBorder={true}
              onClick={onclick}
            />
          </div>
        </>
      )}

      {success === "forgot" && (
        <>
          <div className="flex flex-col justify-center items-center text-center">
            <Typography
              variant="h2"
              isIcon={false}
              className="text-text-primary mobile:text-xl mobile:leading-[32px]"
            >
              ACCOUNT RECOVERY
            </Typography>
            <Typography
              variant="h2"
              isIcon={false}
              className="text-text-primary mobile:text-xl mobile:leading-[32px]"
            >
              SUCCESSFUL!
            </Typography>
          </div>

          <div className="py-3 mobile:py-3">
            <img src={Images.LINE} alt="line" />
          </div>

          <div className="group flex flex-col justify-center items-center mt-5 gap-2">
            <Button
              bgColor={true}
              CLASSNAME="px-2 text-text-primary group-hover:text-text-secondary"
              type="button"
              size="extraSmall"
              color="white"
              isBorderLabel="GO BACK"
              isBorder={true}
              onClick={onclick}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Succuss;
