import { Card } from "components/Atoms/Card/Card";
import { Modal } from "components/Molecules/Modal";
import React from "react";

export const SwitchChain = () => {
  return (
    <Modal blurImg>
      <Card
        pseudoElement="secondary"
        className="!px-10 !pb-5 mobile:!pb-2 mobile:!px-5"
        fullWidth={true}
        childClassName="!overflow-hidden !w-full !h-full"
      >
        <div className=" text-text-primary w-full h-full flex items-center justify-center text-2xl text-center">
          Please check your Wallet App or Extention and switch network to
          polygon.
        </div>
      </Card>
    </Modal>
  );
};
