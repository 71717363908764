import { CancleIcon } from "assets";
import { Card } from "components/Atoms/Card/Card";
import { Modal } from "../Modal";

interface FirstLoginPopUpShownProps {
  onClose: () => void;
}

export const FirstLoginPopUpShownPopup = ({
  onClose,
}: FirstLoginPopUpShownProps) => {
  return (
    <Modal blurImg>
      <Card pseudoElement="perfect" isCrossIconVisible crossOnClick={()=>{
        onClose()
      }}>
        <div className="flex flex-col justify-center items-center text-center text-text-primary ">
            <h1 className="text-xxl font-bold">Note</h1>
          <p className="text-left mobile:text-large mb-1 !text-medium px-5">
            Twitter Quests will not be available if the user connects via Email,
            User Name, Wallet Address and Password. Please ensure you are
            connected through Twitter 'Sign In with X' for the visibility of
            Twitter Quests
          </p>
        </div>
      </Card>
    </Modal>
  );
};
