import { EyeIcon, EyeSlashIcon } from "assets";
import { Images } from "assets/Images";
import { Button } from "components/Atoms/Button/Button";
import { Card } from "components/Atoms/Card/Card";
import { Textfield } from "components/Atoms/Textfield/Textfield";
import { Typography } from "components/Atoms/Typography/Typography";
import { HeroSection } from "components/Molecules/HeroSection";
import { useUser } from "context/userContext";
import { useFormik } from "formik";
import useChangePassword from "hooks/auth/useChangePassword";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

const ChangePassword = () => {
  const navigate = useNavigate();
  const {user} = useUser()
  const { handleChangePasswordAsync, status } = useChangePassword();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const validationSchema = Yup.object().shape(
    {
      oldPassword: Yup.string().required("Old password is required"),      
      newPassword: Yup.string()
        .min(8, "Password must be at least 8 characters long")
        .max(20, "Password must be at most 20 characters long")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[0-9]/, "Password must contain at least one numeric digit")
        .matches(
          /[!@#$%^&*(),.?":{}|<>]/,
          "Password must contain at least one special character"
        )
        .notOneOf([Yup.ref('oldPassword')], 'New password must be different from the old password')
        .required("New password is required"),      
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Passwords must match")
        .required("Confirm Password is required"),
    },
  )
  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      await handleChangePasswordAsync({
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      });
      resetForm();
    },
  });

  useEffect(()=>{
    if(!user?.loginType){
      navigate('/profile')
    }
  },[user])

  return (
    <HeroSection bgSrc={Images.BG}>
      <Card
        pseudoElement="perfect"
        isCrossIconVisible
        crossOnClick={() => navigate("/profile")}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="flex justify-center items-center text-center">
            <Typography
              variant="h2"
              isIcon={false}
              className="text-text-primary mobile:text-xl mobile:leading-[32px]"
            >
              CHANGE PASSWORD
            </Typography>
          </div>
          <div className="py-3 mobile:py-3">
            <img src={Images.LINE} alt="line" />
          </div>

          <div className="flex flex-col gap-3 mobile:gap-2">
            <Typography isIcon={true} font="regular" variant="p">
              Old Password
            </Typography>
            <div className="w-full relative ">
              <Textfield
                placeHolder="Password"
                name="oldPassword"
                value={formik.values.oldPassword}
                // onChange={formik.handleChange}
                onChange={(e: any) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                className={`${
                  formik.touched.oldPassword && formik.errors.oldPassword
                    ? "!border-red-600"
                    : "border-none"
                }`}
              />
              {/* <div className="absolute top-0 right-2 bottom-0 flex justify-center items-center text-light-gray-200">
            {userNameValidation &&
            userNameValidation === 200 &&
            !formik.errors.username ? (
                <RightLogo />
            ) : userNameValidation === 409 &&
                formik.values.username !== "" ? (
                <WrongLogo />
            ) : (
                ""
            )}
            </div> */}
            </div>
            {formik.touched.oldPassword && formik.errors.oldPassword && (
              <Typography
                isIcon={false}
                variant="p"
                font="regular"
                className="!text-red-600"
              >
                {formik.errors.oldPassword}
              </Typography>
            )}
            <Typography isIcon={true} font="regular" variant="p">
              New Password
            </Typography>
            <div className="w-full relative ">
              <Textfield
                placeHolder="Password"
                name="newPassword"
                type={showNewPassword ? "text" : "password"}
                value={formik.values.newPassword}
                // onChange={formik.handleChange}
                onChange={(e: any) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                className={`${
                  formik.touched.newPassword && formik.errors.newPassword
                    ? "!border-red-600"
                    : "border-none"
                }`}
                rightIcon={showNewPassword ? <EyeIcon /> : <EyeSlashIcon />}
                onClick={(e: any) => {
                  e.stopPropagation();
                  setShowNewPassword(!showNewPassword);
                }}
              />
              {/* <div className="absolute top-0 right-2 bottom-0 flex justify-center items-center text-light-gray-200">
            {userNameValidation &&
            userNameValidation === 200 &&
            !formik.errors.username ? (
                <RightLogo />
            ) : userNameValidation === 409 &&
                formik.values.username !== "" ? (
                <WrongLogo />
            ) : (
                ""
            )}
            </div> */}
            </div>
            {formik.touched.newPassword && formik.errors.newPassword && (
              <Typography
                isIcon={false}
                variant="p"
                font="regular"
                className="!text-red-600"
              >
                {formik.errors.newPassword}
              </Typography>
            )}
            <Typography isIcon={true} font="regular" variant="p">
              Confirm Password
            </Typography>
            <div className="w-full relative ">
              <Textfield
                placeHolder="Password"
                name="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                value={formik.values.confirmPassword}
                // onChange={formik.handleChange}
                onChange={(e: any) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                className={`${
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                    ? "!border-red-600"
                    : "border-none"
                }`}
                rightIcon={showConfirmPassword ? <EyeIcon /> : <EyeSlashIcon />}
                onClick={(e: any) => {
                  e.stopPropagation();
                  setShowConfirmPassword(!showConfirmPassword);
                }}
              />
              {/* <div className="absolute top-0 right-2 bottom-0 flex justify-center items-center text-light-gray-200">
            {userNameValidation &&
            userNameValidation === 200 &&
            !formik.errors.username ? (
                <RightLogo />
            ) : userNameValidation === 409 &&
                formik.values.username !== "" ? (
                <WrongLogo />
            ) : (
                ""
            )}
            </div> */}
            </div>
            {formik.touched.confirmPassword &&
              formik.errors.confirmPassword && (
                <Typography
                  isIcon={false}
                  variant="p"
                  font="regular"
                  className="!text-red-600"
                >
                  {formik.errors.confirmPassword}
                </Typography>
              )}

            <div className="group flex flex-col justify-center items-center mt-5 gap-2">
              <Button
                bgColor={true}
                CLASSNAME="px-2 text-text-primary group-hover:text-text-secondary"
                type="submit"
                size="extraSmall"
                color="white"
                isBorderLabel="CONFIRM"
                isBorder={true}
                disable={status.isLoading}
              />
            </div>
          </div>
        </form>
      </Card>
    </HeroSection>
  );
};

export default ChangePassword;
