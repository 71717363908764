import { config, passport } from '@imtbl/sdk';
import { ENV, IMTBL_PUBLISHABLE_KEY, IMTBL_CLIENT_ID, IMTBL_REDIRECT_URL, IMTBL_LOGOUT_URL } from 'constants/config';

const passportInstance = new passport.Passport({
  baseConfig: new config.ImmutableConfiguration({
    environment: ENV === "DEV" ? config.Environment.SANDBOX : config.Environment.PRODUCTION,
    publishableKey: IMTBL_PUBLISHABLE_KEY,
  }),
  clientId: `${IMTBL_CLIENT_ID}`,
  redirectUri: `${IMTBL_REDIRECT_URL}`,
  logoutRedirectUri: `${IMTBL_LOGOUT_URL}`,
  audience: "platform_api",
  scope: "openid offline_access email transact",
  logoutMode: "silent",
});

const provider: any = passportInstance.connectEvm();

async function handleRedirect() {
  if (window.location.pathname === "/redirect") {
    await passportInstance.loginCallback();
  }
}

handleRedirect();

const immutablePassport = { provider, instance: passportInstance };

export default immutablePassport;