import { EyeIcon, EyeSlashIcon } from "assets";
import { Images } from "assets/Images";
import { Button } from "components/Atoms/Button/Button";
import { Textfield } from "components/Atoms/Textfield/Textfield";
import { Typography } from "components/Atoms/Typography/Typography";
import { API_ROUTES } from "constants/API_ROUTES";
import { useFormik } from "formik";
import useSigninAuth from "hooks/auth/useSigin";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
const CryptoJS = require("crypto-js");

const Signin = () => {
  const navigate = useNavigate();
  const rem = localStorage.getItem("remember")
    ? JSON.parse(localStorage.getItem("remember") || "")
    : "";
  const decryptedPass = CryptoJS.AES.decrypt(
    rem?.password || "",
    "secret key 123"
  ).toString(CryptoJS.enc.Utf8);
  const [rememberme, setRememberMe] = useState<boolean>(false);
  const { handleSigninAsync, status } = useSigninAuth();
  const [showPassword, setShowPassword] = useState(false);
  const validationSchema = Yup.object({
    email: Yup.string()
      .matches(/^\S*$/, "No spaces allowed")
      .required("Required"),
    password: Yup.string().required("Password is required"),
  });
  const formik = useFormik({
    initialValues: {
      email: (rem?.input as string) || "",
      password: (decryptedPass as string) || "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (value, { resetForm }) => {
      await handleSigninAsync({
        input: value.email,
        password: value.password,
      });
      if (rememberme) {
        const encrypted = CryptoJS.AES.encrypt(
          value.password,
          "secret key 123"
        ).toString();
        const remVal = {
          input: value.email,
          password: encrypted,
        };
        localStorage.setItem("remember", JSON.stringify(remVal));
      } else {
        localStorage.removeItem("remember");
      }
      resetForm();
    },
  });

  useEffect(() => {
    const rem = localStorage.getItem("remember");
    if (rem) {
      setRememberMe(true);
    }
  }, []);

  return (
    <>
      <div className="flex justify-center items-center text-center">
        <Typography
          variant="h2"
          isIcon={false}
          className="text-text-primary mobile:text-xl mobile:leading-[32px]"
        >
          BLACK PASS SIGN IN
        </Typography>
      </div>
      <div className="py-3 mobile:py-3">
        <img src={Images.LINE} alt="line" />
      </div>

      <div className="w-full">
        <Link
          to={`${API_ROUTES.TWITTER_VERIFICATION}`}
          className="mx-auto w-full"
        >
          <Button
            bgColor={true}
            CLASSNAME="px-2  text-text-primary"
            className="!w-full"
            type="button"
            size="extraSmall"
            color="white"
            isBorderLabel="Sign In with X"
            isBorder={true}
          />
        </Link>
        <Typography
          onClick={() => navigate("/account-recovery")}
          pclassName="justify-end"
          isIcon={false}
          className="text-text-secondary underline text-sm cursor-pointer py-1 mt-1"
        >
          Recover Account
        </Typography>
      </div>

      <div className="flex flex-row justify-center items-center my-3 gap-3">
        <div className="border-b-2 border-dotted w-full"></div>
        <Typography isIcon={false} className="text-text-light font-bold">
          OR
        </Typography>
        <div className="border-b-2 border-dotted w-full"></div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col gap-3 mobile:gap-2">
          <div className="w-full relative">
            <Textfield
              placeHolder="Email, User Name or Wallet Address"
              name="email"
              value={formik.values.email}
              // onChange={formik.handleChange}
              onChange={(e: any) => {
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              className={`${
                formik.touched.email && formik.errors.email
                  ? "!border-red-600"
                  : "border-none"
              }`}
            />
            {/* <div className="absolute top-0 right-2 bottom-0 flex justify-center items-center text-light-gray-200">
                {userNameValidation &&
                userNameValidation === 200 &&
                !formik.errors.username ? (
                  <RightLogo />
                ) : userNameValidation === 409 &&
                  formik.values.username !== "" ? (
                  <WrongLogo />
                ) : (
                  ""
                )}
              </div> */}
          </div>
          {formik.touched.email && formik.errors.email && (
            <Typography
              isIcon={false}
              variant="p"
              font="regular"
              className="!text-red-600"
            >
              {formik.errors.email}
            </Typography>
          )}
          <div className="w-full relative mt-1">
            <Textfield
              placeHolder="Password"
              type={showPassword ? "text" : "password"}
              name="password"
              value={formik.values.password}
              // onChange={formik.handleChange}
              onChange={(e: any) => {
                formik.handleChange(e);
                //   checkEmail(e.target.value);
              }}
              onBlur={formik.handleBlur}
              className={`${
                formik.touched.password && formik.errors.password
                  ? "!border-red-600"
                  : "border-none"
              }`}
              rightIcon={showPassword ? <EyeIcon /> : <EyeSlashIcon />}
              onClick={(e: any) => {
                e.stopPropagation();
                setShowPassword(!showPassword);
              }}
            />
            {/* <div className="absolute top-0 right-2 bottom-0 flex justify-center items-center text-light-gray-200">
                {emailValidation &&
                emailValidation === 200 &&
                !formik.errors.email ? (
                  <RightLogo />
                ) : emailValidation === 409 && formik.values.email !== "" ? (
                  <WrongLogo />
                ) : (
                  ""
                )}
              </div> */}
          </div>
          {formik.touched.password && formik.errors.password && (
            <Typography
              isIcon={false}
              variant="p"
              font="regular"
              className="!text-red-600"
            >
              {formik.errors.password}
            </Typography>
          )}

          <div className="w-full flex flex-row items-center justify-between mobile:flex-col mobile:items-end mobile:gap-2">
            <div className="flex flex-row items-center gap-1 ">
              <label className="custom-checkbox text-text-primary">
                <input
                  type="checkbox"
                  checked={rememberme}
                  onChange={(e) => {
                    console.log(e.target.checked, "e.target.checked");
                    setRememberMe(e.target.checked);
                  }}
                />
                <span className="checkmark"></span>
                Remember Me
              </label>
            </div>
            <Typography
              onClick={() => navigate("/forgot-password")}
              isIcon={false}
              className="text-text-secondary text-sm cursor-pointer"
            >
              Forgot Password?
            </Typography>
          </div>

          <div className="group flex flex-col justify-center items-center mt-5 mobile:mt-2 gap-2">
            <Button
              bgColor={true}
              CLASSNAME="px-2 text-text-primary group-hover:text-text-secondary"
              type="submit"
              size="extraSmall"
              color="white"
              isBorderLabel="SIGN IN"
              isBorder={true}
              disable={status.isLoading}
            />
            <Typography
              isIcon={false}
              className="text-text-primary flex flex-row items-center gap-1 mobile:flex-col mobile:gap-0"
            >
              Don't have an account?{" "}
              <Typography
                onClick={() => navigate("/sign-up")}
                isIcon={false}
                className="text-text-secondary cursor-pointer"
              >
                Sign Up
              </Typography>
            </Typography>
          </div>
        </div>
      </form>
    </>
  );
};

export default Signin;
