import { Images } from "assets/Images";
import { Button } from "components/Atoms/Button/Button";
import { Textfield } from "components/Atoms/Textfield/Textfield";
import { Typography } from "components/Atoms/Typography/Typography";
import { useFormik } from "formik";
import useSendRecovery from "hooks/auth/useSendRecovery";
import * as Yup from "yup";
import TimeoutButton from "./TimeoutButton";
import { useState } from "react";
import { BackIcon } from "assets";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [isSendLink, setIsSendLink] = useState<boolean>(false);
  const { handleSendRcoveryAsync, status } = useSendRecovery();
  const validationSchema = Yup.object({
    email: Yup.string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email address"
      )
      .max(50, "Email must be at most 50 characters")
      .email("Enter valid Email")
      .required("Email is required"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (value) => {
      await handleSendRcoveryAsync({
        input: value.email,
      });
      setIsSendLink(true);
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div onClick={()=>navigate('/sign-in')} className="text-text-primary cursor-pointer absolute top-5 left-5 z-50 mobile:top-3 mobile:left-3">
        <BackIcon /> 
      </div>
      <div className="flex justify-center items-center text-center">
        <Typography
          variant="h2"
          isIcon={false}
          className="text-text-primary mobile:text-xl mobile:leading-[32px]"
        >
          FORGOT PASSWORD
        </Typography>
      </div>
      <div className="py-3 mobile:py-3">
        <img src={Images.LINE} alt="line" />
      </div>

      <Typography
        isIcon={false}
        className="text-text-light text-center uppercase my-3"
      >
        You will receive a link to verify your email at the registered address.
        You can use that link to reset your password.
      </Typography>

      <div className="flex flex-col gap-3 mobile:gap-2">
        <Typography isIcon={true} font="regular" variant="p">
          Enter Registered Email
        </Typography>
        <div className="w-full relative ">
          <Textfield
            placeHolder="Email"
            name="email"
            value={formik.values.email}
            // onChange={formik.handleChange}
            onChange={(e: any) => {
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            className={`${
              formik.touched.email && formik.errors.email
                ? "!border-red-600"
                : "border-none"
            }`}
          />
          {/* <div className="absolute top-0 right-2 bottom-0 flex justify-center items-center text-light-gray-200">
                {userNameValidation &&
                userNameValidation === 200 &&
                !formik.errors.username ? (
                  <RightLogo />
                ) : userNameValidation === 409 &&
                  formik.values.username !== "" ? (
                  <WrongLogo />
                ) : (
                  ""
                )}
              </div> */}
        </div>
        {formik.touched.email && formik.errors.email && (
          <Typography
            isIcon={false}
            variant="p"
            font="regular"
            className="!text-red-600"
          >
            {formik.errors.email}
          </Typography>
        )}

        <div className="flex flex-col justify-center items-center mt-5 gap-2">
          <Button
            bgColor={true}
            CLASSNAME="px-2 text-text-primary group-hover:text-text-secondary"
            type="submit"
            size="extraSmall"
            color="white"
            isBorderLabel="SEND LINK"
            isBorder={true}
            disable={status.isLoading || isSendLink}
          />
          <Typography
            isIcon={false}
            className="text-text-primary text-center"
          >
            Didn't receive a link?{" "}
            <TimeoutButton
              buttonName="Resend"
              onclick={() =>
                handleSendRcoveryAsync({ input: formik.values.email })
              }
              isSendLink={isSendLink}
              time={20}
            />
          </Typography>
        </div>
      </div>
    </form>
  );
};

export default ForgotPassword;
