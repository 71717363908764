import { SUPER_NOVA_STAKE_CONTRACT_ADDRESS } from "constants/config";
import { useEffect, useState } from "react";
import { useReadContract } from "wagmi";
import StackAbi from "Abi/stakingManagerAbi.json";

const useStakeNftData = (address:string) => {
    const [data, setData] = useState<null | [[],[]]>(null);
    const [isLoading, setIsLoading] = useState(true);
    
    const { data: stakeNftData, isLoading: contractLoading , refetch:refetchGetStake}:any = useReadContract({
      address: SUPER_NOVA_STAKE_CONTRACT_ADDRESS,
      abi: StackAbi.abi,
      functionName: "getStakedNFTs",
      args: [address],
    });
  
    useEffect(() => {
      if (!contractLoading && stakeNftData) {
        setData(stakeNftData);
        setIsLoading(false);
      }
      else if(!contractLoading && !stakeNftData){
        setIsLoading(false);
      }
    }, [contractLoading, stakeNftData]);
  
    return { data, isLoading, refetchGetStake};
  };
  
  export default useStakeNftData;