import { useUser } from "context/userContext";
import { useCustomError } from "hooks/accessTokenExpire";
import { useEffect, useState } from "react";
const { DateTime } = require("luxon");

interface TimmerInterface {
  startTime: number;
  endTime: number;
}

export const useDailyBonus = (isDepend?: boolean , start_time ?: string | number  , end_time ?: string | number) => {
   start_time ??= 0
   end_time ??= 0
  const { setdailyBonus, setData3X } = useUser();
  const [data, setData] = useState<TimmerInterface>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isStartTime, setIsStartTime] = useState<boolean>(false);

  const { handleError } = useCustomError();  

  console.log(start_time, end_time,"::::::::")
  const handleGet3XReferrals = async() => {
    try{
        setIsLoading(true)

        const currentTimestampSeconds = DateTime.now().toSeconds();

        // Check if current time is between start time and end time
        if( currentTimestampSeconds <= start_time){
            handleCheckTimmer(start_time)                
        }
        else if (currentTimestampSeconds >= start_time && currentTimestampSeconds <= end_time) {                
            setIsStartTime(true)
            setdailyBonus && setdailyBonus(true)
        } else {                
            setIsStartTime(false)
            setdailyBonus && setdailyBonus(false)
        }

        setData({
            startTime : +start_time,
            endTime: +end_time
        })
        setData3X && setData3X({
            startTime : +start_time,
            endTime: +end_time
        })
    }
    catch(error:any){
        handleError(error)
    }
    finally{
        setIsLoading(false)
    }
}

  const handleCheckTimmer = (startTime: number| string) => {
    let timeout: any;
    if (timeout) {
      clearInterval(timeout);
    }

    timeout = setInterval(() => {
      const currentTimestampSeconds = DateTime.now().toSeconds();
      if (currentTimestampSeconds >= startTime) {
        setIsStartTime(true);
        setdailyBonus && setdailyBonus(true);
        clearInterval(timeout);
        return;
      }
    }, 1000);
  };

  useEffect(() => {
    if (isDepend) {
      handleGet3XReferrals();
    }
  }, [isDepend,start_time,end_time]);

  return {
    data,
    handleGet3XReferrals,
    isLoading,
    isStartTime,
    setIsStartTime,
  };
};
