import { Button } from 'components/Atoms/Button/Button'
import { Card } from 'components/Atoms/Card/Card'
import { Modal } from 'components/Molecules/Modal'
import React from 'react'

export default function ShowError({handleDisconnect , walletAddress} : {handleDisconnect:()=>void; walletAddress:string;}) {
  return (
    <Modal blurImg>
      <Card
        pseudoElement="default"
        className="!px-10 !pb-5 mobile:!pb-2 mobile:!px-5 !h-min"
        fullWidth={true}
        childClassName="!overflow-hidden !w-full !h-full"
      >
        <div className=" text-text-primary w-full h-full flex items-center justify-center text-2xl text-center mb-2">
           <span>Please use wallet address <br></br>(<span className='break-all'>{walletAddress}</span>) that has already been added to your account</span>
        </div>
        <Button
              size="medium"
              color="white"
              bgColor
              isBorder={true}              
              isBorderLabel="disconnect Wallet"
              CLASSNAME={`text-text-primary group-hover:text-text-secondary px-3`}
              className="mobile:!w-full md:!w-40 mx-auto active:scale-95 whitespace-nowrap !h-7"
              onClick={handleDisconnect}
            />
      </Card>
    </Modal>
  )
}
