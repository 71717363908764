import { CancleIcon } from "assets";
import { Images } from "assets/Images";
import { Card } from "components/Atoms/Card/Card";
import LazyImage from "components/LazyImage";
import { Modal } from "components/Molecules/Modal";

export const BanVideo = ({
  onClose,
  onclick,
}: {
  onClose: () => void;
  onclick: () => void;
}) => {
  return (
    <Modal blurImg>
      <Card
        pseudoElement="default"
        className=" !p-0 !h-min relative overflow-hidden"
        childClassName="!p-0"
      >
        <button
          onClick={onClose}
          className="cursor-pointer absolute top-2 right-2 z-20"
        >
          <CancleIcon />
        </button>
        <div className="relative">
          <div onClick={onclick}>
            <LazyImage
              lowQualitySrc={Images.BanWebPopup}
              highQualitySrc={Images.BanWebPopup}
              alt="Super Nova Key"
              className="hidden md:block"
            />
            <LazyImage
              lowQualitySrc={Images.BanMobPopup}
              highQualitySrc={Images.BanMobPopup}
              alt="Super Nova Key"
              className="md:hidden"
            />

            {/* <video
            src={VIDEOS.UPDATE_WEB_ASTRANOVA}
            autoPlay
            muted
            loop
            playsInline
            className="hidden md:block"
            controls={false}
          /> */}
            {/* <video
            src={VIDEOS.UPDATE_MOB_ASTRANOVA}
            autoPlay
            muted
            loop
            playsInline
            className="md:hidden"
            controls={false}
          /> */}
            {/* <img src={Images.MOBBAN} alt="mob ban" className="md:hidden h-full w-full" /> */}
          </div>
        </div>
        {/* <div>
          <CountdownTimer positionCenter={true} />
        </div> */}
      </Card>
    </Modal>
  );
};
