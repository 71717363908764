import { CancleIcon } from "assets";
import { Button } from "components/Atoms/Button/Button";
import { Card } from "components/Atoms/Card/Card";
import { Typography } from "components/Atoms/Typography/Typography";
import { Modal } from "components/Molecules/Modal";
import { SuperNovaCard } from "../Card";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAccount } from "wagmi";
import { Smallloader } from "components/Loader";
import { useUser } from "context/userContext";

export const AddNFT = ({
  onClose,
  onAddNFT,
  isloading,
  nftTokenIds,
  setnftTokenIds,
  totalNoOfStakes,
  isStakeLoading,
  checkTime,
  setUserStakeList,
  userStakeList,
}: {
  onClose: () => void;
  onAddNFT?: (address: string, userId: string, selectedNft: number[]) => any;
  isloading: boolean;
  nftTokenIds: any[];
  setnftTokenIds: React.Dispatch<any>
  totalNoOfStakes?:number;
  isStakeLoading?:boolean;
  checkTime?:()=>boolean | null;
  setUserStakeList: React.Dispatch<any>
  userStakeList:any[];
}) => {
  const [selectNft, setSelectNft] = useState<number[]>([]);
  const { user } = useUser();
  const { address, isConnected } = useAccount();

  const handleAddNft = (data: number) => {
    let findToken = selectNft.find((tk) => tk === data);
    if(!findToken && (50 - (totalNoOfStakes || 0)) <= selectNft.length){
      toast.error('You cannot Stake more than 50 NFT\'s')
      return;
    }

    if (findToken) {
      //Remove
      setSelectNft(selectNft.filter((tk) => tk !== data));
    } else {
      //Add
      setSelectNft([...selectNft, data]);
    }
  };

  const handleRemoveSelectNft = (selectNft: number[])=> {
    
    let addnft = selectNft.map((nf)=> { 
      const  date = new Date()
      const timestamp = Math.floor(date.getTime() / 1000);
      return {tokenId : nf , stakedOn: timestamp}
    })

    setUserStakeList([...userStakeList].concat(addnft))
    setnftTokenIds(nftTokenIds.filter((nf)=> !selectNft.includes(nf) ))
  }

  const handleSelectAll = ()=> {
    const maxLength = 50;
    const fildata = nftTokenIds.length > maxLength ? nftTokenIds.slice(0, maxLength) : nftTokenIds;
    setSelectNft([...fildata])
  }

  const handleClear = ()=> {
    setSelectNft([])
  }

  
  return (
    <Modal blurImg>
      <Card
        pseudoElement="default"
        className="!px-10 !pb-5 mobile:!pb-2 mobile:!px-5"
        fullWidth={true}
        childClassName="!overflow-hidden !w-full !h-full"
      >
        <button
          onClick={() => (!isloading && !isStakeLoading) && onClose()}
          className="cursor-pointer absolute top-2 right-2 z-20"
        >
          <CancleIcon />
        </button>
        <div className="text-text-primary w-full h-full flex flex-col items-center justify-between">
          {/* //head */}
          <div className="">
            <Typography isIcon={false} className="text-2xl uppercase text-center mx-auto">
              Add NFT
            </Typography>
            <Typography isIcon={false} className="text-sm">
              {`${selectNft.length} ${selectNft.length > 1 ? 'NFT\'s' : 'NFT'} Selected`}
            </Typography>
          </div>
          {/* body */}
          <div className="h-[80%] w-full overflow-hidden overflow-y-auto">
            {isloading ? (
              <Smallloader />
            ) : nftTokenIds && nftTokenIds.length === 0 ? (
              <Typography
                isIcon={false}
                font="bold"
                pclassName="w-full !h-full justify-center"
                className="text-2xl"
              >
                No NFT
              </Typography>
            ) : (
              <div className="grid md:grid-cols-2 justify-center items-center gap-3 gap-y-5 w-full min-h-full p-5">
                {nftTokenIds && nftTokenIds.map((stake, idx) => (
                  <SuperNovaCard
                    key={stake + idx}
                    isShadowActiveOnFll={false}
                    data={stake}
                    isSelectionButton={true}
                    handleAddNft={handleAddNft}
                    selectNFT={selectNft}
                    isLoading={isStakeLoading}
                  />
                ))}
              </div>
            )}
          </div>
          {/* footer */}
          <div className="w-full flex flex-col md:flex-row items-center md:gap-x-3 mobile:gap-y-2">
            <div className="flex flex-row items-center gap-x-3 mobile:justify-between mobile:w-full">
            <Button
              size="medium"
              color="white"
              bgColor
              isBorder={true}
              disable={isloading || isStakeLoading}
              isBorderLabel="Select All"
              CLASSNAME={`text-text-primary group-hover:text-text-secondary px-3`}
              className="mobile:!w-full md:!w-40 mx-auto active:scale-95 whitespace-nowrap !h-7"
              onClick={handleSelectAll}
            />
            <Button
              size="medium"
              color="white"
              bgColor
              isBorder={true}
              disable={isloading || isStakeLoading}
              isBorderLabel="Clear"
              CLASSNAME={`text-text-primary group-hover:text-text-secondary px-3`}
              className="mobile:!w-full md:!w-40 mx-auto active:scale-95 !h-7"
              onClick={handleClear}
            />
            </div>
            <Button
              size="medium"
              color="white"
              bgColor
              isBorder={true}
              disable={isloading || isStakeLoading}
              isBorderLabel="ADD"
              CLASSNAME={`text-text-primary group-hover:text-text-secondary px-3`}
              className="mobile:!w-full md:!w-40 mx-auto active:scale-95 !h-7"
              onClick={async() => {
                if (selectNft.length === 0) {
                  toast.error("Please Select first NFT");
                } else if (address && user?.id) {
                  if(checkTime && !checkTime() && checkTime() !== null){
                    let val =  onAddNFT && await onAddNFT(address, user?.id, selectNft);
                    if(val){
                      handleRemoveSelectNft(selectNft)
                    }
                  }
                } else {
                  //console.log("NoUser or Noaddress");
                }
              }}
            />
          </div>
        </div>
      </Card>
    </Modal>
  );
};
