import { useCustomError } from "hooks/accessTokenExpire";
import { useState } from "react";
import { toast } from "react-toastify";
import { POST_SET_PASSWORD_AUTH, POST_SIGNUP_SETPASSWORD } from "services/apiService";

interface StatusInterface {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
}

const useSetPassword = () => {
  const [status, setStatus] = useState<StatusInterface>({
    isLoading: false,
    isError: false,
    isSuccess: false,
  });

  const { handleError } = useCustomError();
  const handleSetPasswordForNewUsers = async (body: {
    token: string;
    password: string;
  }) => {
    try {
      setStatus({
        ...status,
        isError: false,
        isSuccess: false,
        isLoading: true,
      });
      const req = await POST_SIGNUP_SETPASSWORD(body);
      const res = req.data;
      if (res.success) {
        setStatus(prev => {return{
          ...prev,
          isSuccess: true,
        }});
        toast.success(res.message);
      }
    } catch (error: any) {
      setStatus(prev => {return{
        ...prev,
        isError: true,
      }});
      handleError(error);
    } finally {
      setStatus(prev => {return{
        ...prev,
        isLoading: false,
      }});
    }
  };

  const handleSetPassword = async (body: {
    token: string;
    password: string;
  }) => {
    try {
      setStatus({
        ...status,
        isError: false,
        isSuccess: false,
        isLoading: true,
      });
      const req = await POST_SET_PASSWORD_AUTH(body);
      const res = req.data;
      if (res.success) {
        setStatus(prev => {return{
          ...prev,
          isSuccess: true,
        }});
        toast.success(res.message);
      }
    } catch (error: any) {
      setStatus(prev => {return{
        ...prev,
        isError: true,
      }});
      handleError(error);
    } finally {
      setStatus(prev => {return{
        ...prev,
        isLoading: false,
      }});
    }
  };

  return {
    handleSetPasswordForNewUsers,
    handleSetPassword,
    status,
  };
};

export default useSetPassword;